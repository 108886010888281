import { useAuthStore } from '@/stores/auth';

export default async function authInterceptor(config) {
    const authStore = useAuthStore();

    if (authStore.userToken) {
        config.headers['Authorization'] = `Bearer ${authStore.userToken}`;
    }

    return config;
}
