import axios from 'axios';

import authInterceptor from './auth.interceptor';
import refreshInterceptor from './refresh.interceptor';

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
});

axiosInstance.interceptors.request.use(authInterceptor);
axiosInstance.interceptors.response.use((response) => response, refreshInterceptor);

export default axiosInstance;
